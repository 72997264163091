import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Container from '../Container';
import Row from '../Container/row';
import Col from '../Container/column';

import { TitleColour } from '../../utils/variables';
import { CtaButtonPlaceholder, CtaTitlePlaceholder } from './placeholders';

import './style.css';

import loadComponents from '../Loadable';

const H3 = loadComponents('h3');
const Button = loadComponents('cta-button');

export default function CtaBanner() {
  const data = useStaticQuery(graphql`
    query CtaQuery {
      wp {
        ...CallToActions
      }
    }
  `);

  const { title, text } = data.wp.callToAction.callToAction.callToAction;
  const isActive = title !== null && text !== null;

  return (
    isActive && (
      <div
        className="ctaOuter"
        style={{
          backgroundColor: TitleColour,
        }}
      >
        <Container>
          <Row align="center" justify="space-between">
            <Col className="content" lg={{ width: 'auto' }}>
              <H3
                size
                margin={text ? '1.875rem' : '0px'}
                colour="#fff"
                fallback={
                  <CtaTitlePlaceholder className="ctaTitlePlaceholder" />
                }
              >
                {title}
              </H3>
              {text && <p>{text}</p>}
            </Col>
            <Col className="action" lg={{ width: 'auto' }}>
              <Button fallback={<CtaButtonPlaceholder />} />
            </Col>
          </Row>
        </Container>
      </div>
    )
  );
}
